const addresses = {
  multicall3:     "0xcA11bde05977b3631167028862bE2a173976CA11",
  usdc:           "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
  beachheadToken: "0x010e37DFDE820066ED8B4661338B7E093E7e7429",
  beachheadJudge: "0xf258b6c89835e0Bde20bd5A754c76150586763c5",
  beachhead:      "0x006a1A936957b5286a92a4E1886e01C1985611bc",

  // Uniswap addresses on Base
  uniswap: {
    UniversalRouterV1_2:           "0xeC8B0F7Ffe3ae75d7FfAb09429e3675bb63503e4",
    UniversalRouterV1_2_V2Support: "0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD",
    UnsupportedProtocol:           "0x9E18Efb3BE848940b0C92D300504Fb08C287FE85",
    Permit2:                       "0x000000000022D473030F116dDEE9F6B43aC78BA3",
  },

  // Pool addresses
  usdcWethPool:   "0x88a43bbdf9d098eec7bceda4e2494615dfd9bb9c",
  wethBchdPool:   "0x6497848f85a7109404e881f5dad9fbb63734fdcf",

  // WETH token address on Base
  weth:           "0x4200000000000000000000000000000000000006",
};

export default addresses;
